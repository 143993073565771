// import LinearGradient from '@basicComponents/linear-gradient';
import theme from '@style';
import React, {memo, useState} from 'react';
import {LayoutChangeEvent, StyleSheet, View} from 'react-native';
import {useResponsiveDimensions} from '@/utils';
import Text from '@basicComponents/text';
import {vipColors} from './vip.options';
import {fill} from '@/components/style';

const {flex} = theme;

export interface VipProgress {
  currentLevel: number;
  nextCurrentLevel: number;
  current: number;
  total: number;
  hasCurrentText?: boolean;
}

function VipProgress(props: VipProgress) {
  const {currentLevel, nextCurrentLevel, current, total} = props;
  const [width, setWidth] = useState<number>(0);
  const size = useResponsiveDimensions();
  const height = (4 * size.width) / 375;
  const styles = StyleSheet.create({
    progressBg: {
      flex: 1,
      height,
      backgroundColor: '#8F4e00',
      overflow: 'hidden',
    },
    alighStratch: {alignSelf: 'stretch'},
    opacity: {
      opacity: 0.5,
    },
  });
  const handleLayout = (e: LayoutChangeEvent) => {
    setWidth(e.nativeEvent.layout.width);
  };

  const hexToRgba = (hex: any, opacity: number = 1) => {
    return hex && hex.replace(/\s+/g, '').length === 7
      ? 'rgba(' +
          parseInt('0x' + hex.slice(1, 3)) +
          ',' +
          parseInt('0x' + hex.slice(3, 5)) +
          ',' +
          parseInt('0x' + hex.slice(5, 7)) +
          ',' +
          opacity +
          ')'
      : '';
  };

  const renderBadgeItem = (currenLevel: number) => {
    return (
      <View
        style={[
          theme.padding.lrxxs,
          theme.borderRadius.xs,
          {
            borderColor: hexToRgba(vipColors[currenLevel], 0.6),
            borderWidth: 1,
            backgroundColor: hexToRgba(vipColors[currenLevel], 0.2),
          },
        ]}>
        <Text
          fontSize={8}
          fontFamily={'fontDinBold'}
          color={hexToRgba(vipColors[currenLevel])}
          style={[]}>
          LV.{currenLevel}
        </Text>
      </View>
    );
  };

  const progressWidth = (width * current) / total;
  const innerStyles = StyleSheet.create({
    progress: {
      width: progressWidth,
      height,
    },
  });
  return (
    <View onLayout={handleLayout} style={[flex.col, theme.fill.fillW]}>
      <View style={[flex.row, fill.fillW, flex.between, flex.centerByCol]}>
        {renderBadgeItem(currentLevel)}
        {/* 此处shadow不好做，目前暂时换为纯色做 */}
        <View style={[styles.progressBg]}>
          {/* <LinearGradient
            start={{x: 0, y: 0}}
            end={{x: 1, y: 0}}
            colors={['#7601F7', '#8501E0']}
            style={[innerStyles.progress]}
          /> */}
          <View style={[innerStyles.progress, {backgroundColor: '#3107C4'}]} />
        </View>
        {renderBadgeItem(nextCurrentLevel)}
      </View>
    </View>
  );
}

export default memo(VipProgress);
